const widths = {
  max: {
    contractDataRowColumn: '32rem',
    emptyListInfoBox: '36rem',
    noContractInfoBox: '36rem',
    virtualizedList: '24rem',
    virtualizedListItem: '19.5rem',
    virtualizedListHeader: '22.4rem',
    root: '192rem',
    tooltip: '29rem',
    benefitsVariantsDropdown: '20.8rem',
    annualLeaveModalColumn: '9.2rem',
    projectListItemMembers: '17.6rem',
    timeReportStatsCell: '55.2rem',
    listSearchPill: '85rem',
    projectPositionDropdown: '23rem',
  },
  min: {
    dropdown: '10rem',
    positionRateInput: '1rem',
    listSearchPill: '25rem',
    transfersSummaryModal: '45.6rem',
    benefitsHistoryModal: '30rem',
    workedTimeStatsCell: '28rem',
    positionHistory: '79.2rem',
  },
  default: {
    datePickerCell: '3.6rem',
    datePickerHighlight: '.4rem',
    datePickerYear: '6.3rem',
    datePicker: '28.4rem',
    invoiceNumberInput: '20rem',
    signDraftInput: '16rem',
    navbar: '26.4rem',
    confirmationModal: '45.6rem',
    timeReportModal: '45.6rem',
    login: '52rem',
    spinner: '2rem',
    saveAsReadyModal: '36rem',
    contractActionsModals: '36rem',
    salariesLabel: '10.7rem',
    inlineSaveButton: '3.4rem',
    rightSidebar: '38.4rem',
    reportBlock: '36rem',
    drawerSideModal: '79.2rem',
    drawer: '36rem',
    currencyIcon: '2rem',
    transferSummaryModal: '101.8rem',
    transferSummaryTable: '70.9rem',
    transferSummaryTableCurrenciesColumn: '11.6rem',
    workingDayCheckbox: '3.6rem',
    workingDayHoursInput: '7.4rem',
    projectAssignmentFieldLabel: '10.5rem',
  },
};

export type Width = keyof typeof widths;
export default widths;
